import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

const LayoutWrapper = (WrappedComponent, Layout) => {
	class WC extends Component {
		render() {
			return (
				<Layout {...this.props}>
					<Fragment>
						<WrappedComponent {...this.props} />
					</Fragment>
				</Layout>
			);
		}
	}

	return withTranslation()(WC);
};

export default LayoutWrapper;
