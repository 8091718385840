import axios from 'axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import config from '../../config.json';

import { getUserPoolIdCompleted, getUserPoolIdFailed } from './actions.js';
import { GET_USER_POOL_ID } from './actionTypes.js';

export const userPoolIdEpic = (action$) =>
	action$.pipe(
		ofType(GET_USER_POOL_ID),
		mergeMap(async (action) => {
			const setBody = {
				email: action.payload
			};

			const getUserPool = await axios.post(
				`${
					config[process.env.REACT_APP_STAGE].api.secondaryGateway
				}/api/userpool`,
				setBody
			);

			return getUserPool;
		}),
		switchMap((res) => [getUserPoolIdCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(getUserPoolIdFailed(error.message)), source)
		)
	);
