import * as UserPoolIdEpic from './epic';
import * as userPoolIdActions from './actions';
import * as userPoolIdActiontypes from './actionTypes';
import { userPoolIdReducer } from './reducer';

const userPoolIdEpic = Object.values({ ...UserPoolIdEpic });

export {
	userPoolIdActions,
	userPoolIdActiontypes,
	userPoolIdEpic,
	userPoolIdReducer
};
