import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { catchError } from 'rxjs/operators';
import { userPoolIdEpic, userPoolIdReducer } from '../containers/LandingPage';

const epics = combineEpics(...userPoolIdEpic);

const rootReducer = combineReducers({
	userPoolIdReducer
});

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error('Root Epic Error Catcher', error);
			return source;
		})
	);

export { rootEpic, rootReducer };
