import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Navbar from './Header/Navbar';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0px',
		position: 'fixed',
		width: '100vw',
		zIndex: 9999,
		height: theme.panel.height
	}
});

const Header = (props) => {
	const { classes } = props;
	const { root } = classes;
	return (
		<div className={root}>
			<Navbar />
		</div>
	);
};

Header.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(Header);
