import React from 'react';
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import LandingPage from '../../pages/LandingPage';

const AppRouter = (props) => {
	return (
		<Router>
			<Switch>
				<Route exact path="/" render={(props) => <LandingPage {...props} />} />
			</Switch>
		</Router>
	);
};

AppRouter.propTypes = {
	t: PropTypes.func
};

export default withTranslation()(AppRouter);
