import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import config from '../../config.json';
import { emailValidation } from '../../validation/emailValidation';
import { userPoolIdActions } from '../../containers/LandingPage';
import { useKeyPress } from './helpers/useKeyPress';
import OrganizationSelect from './OrganizationSelect';
import LinearStepper from './LinearStepper';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	input: {
		width: '15vw'
	},
	root: {
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
		minWidth: '25vw',
		maxWidth: '35vw',
		minHeight: '20vh',
		maxHeight: '50vh',
		overflowY: 'auto',
		overflowX: 'auto',
		border: `2.5px solid ${theme.palette.primary.main}`,
		borderRadius: '25px',
		justifyContent: 'space-around',
		alignItems: 'center',
		'&::-webkit-scrollbar': {
			width: '6px',
			height: '6px'
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.4)',
			margin: '3vh 2vw 3vh 2vw',
			borderRadius: '25px'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			outline: '.5px solid slategrey',
			borderRadius: '25px'
		}
	},
	stepper: {
		marginBottom: '1vh'
	},
	textContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	title: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '1vh'
	}
});

const StepOrchestration = (props) => {
	const {
		classes,
		error,
		getUserTenetList,
		hasBeenLoaded,
		isLoading,
		t,
		userTenetList
	} = props;
	const { input, root, stepper, textContainer, title } = classes;

	const [activeStep, setActiveStep] = useState(0);
	const [emailInput, setEmailInput] = useState('');
	const [emailError, setEmailError] = useState([1]);
	const [CPI, setCPI] = useState();

	const returnPress = useKeyPress('Enter');

	const handleRedirect = useCallback(() => {
		const encodedCPI = encodeURIComponent(CPI);
		window.location.assign(
			`${
				config[process.env.REACT_APP_STAGE].dashboardPageURI
			}?CPI=${encodedCPI}`
		);
	}, [CPI]);

	useEffect(() => {
		if (CPI) {
			handleRedirect();
		}
		if (returnPress && emailError.length === 0) {
			handleNext();
		}
	}, [CPI, handleRedirect, returnPress]);

	const handleInputValidation = (value) => {
		const emailValidationError = emailValidation(value);
		if (emailValidationError.length) {
			setEmailError(emailValidationError);
		} else {
			setEmailError([]);
		}
		setEmailInput(value);
	};

	const handleNext = () => {
		getUserTenetList(emailInput);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSelectTenet = (tenet) => {
		const { label, poolAppClientId, poolId, poolRegion, username } = tenet;

		const cpi = {
			ACID: poolAppClientId,
			ORG: label,
			REG: poolRegion,
			UNAME: username,
			UPID: poolId
		};
		setCPI(JSON.stringify(cpi));
	};

	return (
		<Container maxWidth={false} className={root}>
			<div className={title}>
				<Typography variant="h6">
					{activeStep === 0
						? t('pages.landingpage.greeting')
						: t('pages.landingpage.organization')}
				</Typography>
			</div>
			{activeStep === 0 ? (
				<div className={textContainer}>
					<TextField
						className={input}
						error={emailInput !== '' && emailError.length !== 0}
						helperText={
							emailInput !== '' &&
							emailError.length !== 0 &&
							t('pages.landingpage.error')
						}
						label="Enter Email"
						onChange={(e) => handleInputValidation(e.target.value)}
						variant="outlined"
						value={emailInput}
					/>
				</div>
			) : (
				<div className={textContainer}>
					<OrganizationSelect
						error={error}
						handleSelect={handleSelectTenet}
						hasBeenLoaded={hasBeenLoaded}
						isLoading={isLoading}
						organizations={userTenetList}
					/>
				</div>
			)}
			<div className={stepper}>
				<LinearStepper
					activeStep={activeStep}
					emailError={emailError}
					handleBack={handleBack}
					handleNext={handleNext}
					value={emailInput}
				/>
			</div>
		</Container>
	);
};

StepOrchestration.propTypes = {
	classes: PropTypes.object,
	error: PropTypes.string,
	getUserTenetList: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func,
	userTenetList: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const mapStateToProps = (state) => {
	return {
		error: state.userPoolIdReducer.get('error'),
		hasBeenLoaded: state.userPoolIdReducer.get('hasBeenLoaded'),
		isLoading: state.userPoolIdReducer.get('isLoading'),
		userTenetList: state.userPoolIdReducer.get('userTenetList')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserTenetList: (payload) => {
			dispatch(userPoolIdActions.getUserPoolId(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(StepOrchestration)));
