import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

const styles = (theme) => ({
	button: {
		visibility: 'hidden'
	},
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '30vw'
	}
});

const LinearStepper = (props) => {
	const { activeStep, classes, emailError, handleNext, handleBack, t } = props;
	const { button, root } = classes;

	const steps = [
		t('pages.landingpage.stepper.email'),
		t('pages.landingpage.stepper.organization')
	];

	return (
		<Box className={root}>
			<Button
				variant="outlined"
				color="primary"
				type="button"
				size="small"
				onClick={handleBack}
				disabled={activeStep === 0}
			>
				{t('pages.landingpage.back')}
			</Button>
			<Stepper activeStep={activeStep}>
				{steps.map((label) => {
					return (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<Button
				variant="outlined"
				type="submit"
				className={clsx(activeStep === 1 && button)}
				disabled={emailError.length !== 0}
				color="primary"
				size="small"
				onClick={handleNext}
			>
				{activeStep === 1
					? t('pages.landingpage.finish')
					: t('pages.landingpage.next')}
			</Button>
		</Box>
	);
};

LinearStepper.propTypes = {
	activeStep: PropTypes.number,
	classes: PropTypes.object,
	emailError: PropTypes.array,
	handleBack: PropTypes.func,
	handleNext: PropTypes.func,
	setActiveStep: PropTypes.func,
	t: PropTypes.func,
	value: PropTypes.string
};

export default withStyles(styles)(withTranslation()(LinearStepper));
