import * as actionTypes from './actionTypes';

export const getUserPoolId = (payload) => ({
	type: actionTypes.GET_USER_POOL_ID,
	payload
});

export const getUserPoolIdCompleted = (payload) => ({
	type: actionTypes.GET_USER_POOL_ID_COMPLETED,
	payload
});

export const getUserPoolIdFailed = (payload) => ({
	type: actionTypes.GET_USER_POOL_ID_FAILED,
	payload
});
