import React from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Slyce360Icon from '../../Icons/Slyce360Logo';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	leftContainer: {
		display: 'flex',
		width: theme.panel.width,
		borderRight: `1px solid ${theme.palette.background.default}`
	},
	logo: {
		display: 'flex',
		width: '100%',
		alignContent: 'center',
		marginLeft: '20px',
		'& svg': {
			width: '70%'
		},
		textDecoration: 'none'
	},
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		minHeight: theme.panel.height,
		backgroundColor: theme.palette.navigation.background,
		padding: '0px',
		margin: '0px',
		dropShadow: '0px 2px 2px #00000',
		zIndex: '9999'
	}
});

const Navbar = (props) => {
	const { classes } = props;
	const { leftContainer, logo, root } = classes;

	return (
		<Container maxWidth={false} className={root}>
			<div className={leftContainer}>
				<div className={logo}>
					<Slyce360Icon />
				</div>
			</div>
		</Container>
	);
};

Navbar.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(withTranslation()(Navbar));
