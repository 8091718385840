import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import StepOrchestration from '../../components/input/StepOrchestration';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		width: '100vw'
	}
});

const LandingPage = (props) => {
	const { classes } = props;
	const { root } = classes;

	return (
		<Container maxWidth={false} className={root}>
			<StepOrchestration />
		</Container>
	);
};

LandingPage.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(withTranslation()(LandingPage));
