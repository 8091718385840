import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import OrganizationCards from './OrganizationCards';

const styles = (theme) => ({
	errorMessage: {
		textAlign: 'center'
	},
	root: {
		margin: '1vh'
	}
});

const OrganizationSelect = (props) => {
	const {
		classes,
		error,
		handleSelect,
		hasBeenLoaded,
		isLoading,
		organizations,
		t
	} = props;
	const { errorMessage, root } = classes;

	return isLoading && !hasBeenLoaded ? (
		<CircularProgress />
	) : isLoading && hasBeenLoaded ? (
		<CircularProgress />
	) : hasBeenLoaded && !isLoading && !error ? (
		organizations.size !== 0 && (
			<Box className={root}>
				<OrganizationCards
					handleSelect={handleSelect}
					organizations={organizations}
				/>
			</Box>
		)
	) : (
		error && (
			<Typography variant="body1" className={errorMessage}>
				{t('pages.landingpage.emailnotfound')}
			</Typography>
		)
	);
};

OrganizationSelect.propTypes = {
	classes: PropTypes.object,
	error: PropTypes.string,
	handleSelect: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	organizations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(OrganizationSelect));
