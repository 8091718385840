import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import config from '../../config.json';

import Footer from './Footer';
import Header from './Header';
import Theme from '../Theme';

const styles = (theme) => ({
	background: {
		backgroundImage: `url(${
			config[process.env.REACT_APP_STAGE].assetsURI
		}/public/Slyce360_021822_Member-Landing-Page_Graphic.jpg)`
	},
	dashboardContentWrapper: {
		display: 'flex',
		width: '100vw'
	},
	dashboardPageContent: {
		display: 'flex',
		minHeight: '100vh'
	}
});

const Layout = (props) => {
	const { children, classes } = props;
	const { background, dashboardPageContent, dashboardContentWrapper } = classes;
	return (
		<Theme>
			<div className={dashboardPageContent}>
				<div className={background}>
					<div className={dashboardContentWrapper}>
						<Header />
						{children}
						<Footer />
					</div>
				</div>
			</div>
		</Theme>
	);
};

Layout.propTypes = {
	authUserSession: PropTypes.func,
	children: PropTypes.element,
	classes: PropTypes.object,
	location: PropTypes.object,
	isAuthed: PropTypes.bool,
	t: PropTypes.func
};

export default withStyles(styles)(Layout);
