import { fromJS } from 'immutable';
import {
	GET_USER_POOL_ID,
	GET_USER_POOL_ID_COMPLETED,
	GET_USER_POOL_ID_FAILED
} from './actionTypes';

export const initialState = fromJS({
	error: undefined,
	isLoading: false,
	hasBeenLoaded: false,
	userTenetList: []
});

export const userPoolIdReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_POOL_ID:
			return state.set('isLoading', true).set('error', undefined);

		case GET_USER_POOL_ID_COMPLETED:
			return state
				.set('userTenetList', action.payload)
				.set('error', '')
				.set('isLoading', false)
				.set('hasBeenLoaded', true);

		case GET_USER_POOL_ID_FAILED:
			return state
				.set('isLoading', false)
				.set('error', action.payload)
				.set('userTenetList', []);

		default:
			return state;
	}
};
