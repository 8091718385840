import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import config from '../../config.json';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	card: {
		width: '18vw',
		margin: '1vh'
	},
	cardInfo: {
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	subCardInfo: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'inherit'
	}
});

const OrganizationCards = (props) => {
	const { classes, handleSelect, organizations } = props;
	const { card, cardInfo, subCardInfo } = classes;

	return organizations.map((entry, index) => {
		return (
			<Card key={`${entry.label}-${index}`} className={card}>
				<CardActionArea onClick={() => handleSelect(entry)}>
					<CardContent className={cardInfo}>
						<div className={subCardInfo}>
							<Typography variant="subtitle2">{entry.label}</Typography>
							<Avatar
								alt={entry.label}
								src={`${config[process.env.REACT_APP_STAGE].assetsURI}${
									entry.organizationAvatar
								}`}
							>
								{entry.label.charAt(0)}
							</Avatar>
						</div>
						<div className={subCardInfo}>
							<Chip
								avatar={
									<Avatar
										alt={entry.username}
										src={`${config[process.env.REACT_APP_STAGE].assetsURI}${
											entry.userAvatar
										}`}
									/>
								}
								label={entry.username}
								variant="outlined"
							/>
						</div>
					</CardContent>
				</CardActionArea>
			</Card>
		);
	});
};

OrganizationCards.propTypes = {
	classes: PropTypes.object,
	handleSelect: PropTypes.func,
	organizations: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default withStyles(styles)(OrganizationCards);
