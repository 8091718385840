import { createTheme } from '@mui/material/styles';

const branding = {
	main1: '#51284F',
	main2: '#005776',
	secondary1: '#C1C6C8',
	secondary2: '#63666A'
};

const defaultTheme = {
	panel: {
		height: '3.25rem',
		width: '230px'
	},
	typography: {
		fontFamily: ['Montserrat', 'sans-serif'].join(',')
	},
	palette: {
		background: {
			default: '#ffffff'
		},
		common: {
			black: '#000',
			white: '#fff'
		},
		error: {
			main: '#BF0000'
		},
		navigation: {
			background: '#E0E2E3'
		},
		primary: {
			main: branding.main1,
			alt: branding.main2
		},
		secondary: {
			main: branding.secondary1,
			alt: branding.secondary2
		},
		success: {
			main: '#74B72E'
		},
		warning: {
			main: '#B79906'
		}
	}
};

export default createTheme(defaultTheme);
